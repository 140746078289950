<template>
    <div class="wrap">
        <div class="manageBox">
            <div class="top">
                <el-form :inline="true" :model="queryParams" label-width="90px" class="demo-form-inline">
                    <el-form-item label="申请日期">
                        <el-date-picker
                            v-model="queryParams.time"
                            type="daterange"
                            align="right"
                            unlink-panels
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="审核状态">
                        <el-select class="w200px" v-model="queryParams.state" clearable placeholder="请选择审核状态">
                            <el-option v-for="item in stateOpt" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="当前待审核人" label-width="100px">
                        <el-select class="w200px" v-model="queryParams.auditLevel" clearable placeholder="请选择当前待审核人">
                            <el-option v-for="(item, index) in examineGradeOpt" :key="index" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="身份类别">
                        <el-select class="w200px" v-model="queryParams.subsidyType" clearable placeholder="请选择身份类别">
                            <el-option v-for="(item, index) in personTypeConfig" :key="index" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="搜索内容">
                        <el-input v-model="queryParams.keyword" placeholder="请输入关键字" clearable style="width: 200px" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="search" @click="searchList">搜索</el-button>
                        <el-button type="primary" class="search" plain @click="handlerResetList">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content">
                <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                    <template slot="empty">
                        <IsEmpty />
                    </template>
                    <!--          <el-table-column type="index" label="序号" align="center" width="80" />-->
                    <el-table-column prop="userName" align="center" label="姓名" show-overflow-tooltip />
                    <el-table-column prop="old" align="center" label="年龄" width="160" />
                    <el-table-column prop="creatTime" align="center" label="性别" width="160">
                        <template slot-scope="scope">
                            <span>{{ ['未知', '男', '女'][scope.row.sex * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userPhone" align="center" label="联系电话" />
                    <el-table-column prop="residenceAddress" align="center" label="户籍地址" show-overflow-tooltip />
                    <el-table-column prop="subsidyType" align="center" label="身份类别">
                        <template slot-scope="scope">
                            <span>{{ ['普通老人', '低保老人', '失能老年人', '低保失能老年人'][scope.row.subsidyType] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" align="center" label="申请时间" />
                    <el-table-column prop="state" align="center" label="当前审核状态">
                        <template v-slot="scope">
                            <span>{{ ['暂存', '待审核', '审核通过', '审核不通过'][scope.row.state * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" align="center" label="当前审核人">
                        <template v-slot="scope">
                            <span>{{ ['', '社区', '街道办事处', '区民政局'][scope.row.auditLevel * 1] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="seeDetial(scope.row)">查看</el-button>
                            <el-button type="text" v-if="scope.row.examineState * 1 === 0 && scope.row.state !== 0" @click="toReview(scope.row)">审核</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="total > 0"
                    style="margin: 20px 0"
                    background
                    layout="total, sizes, prev, pager, next"
                    :total="total"
                    :page-size="queryParams.pageSize"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getApplylist } from '@/api/highCollarSubsidy'
import { parseTime } from '@/utils/index'
export default {
    data() {
        return {
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            picker.$emit('pick', [start, end])
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            picker.$emit('pick', [start, end])
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                            picker.$emit('pick', [start, end])
                        }
                    }
                ]
            },
            total: 0,
            loading: false,
            options: [],
            tableData: [],
            time: '',
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                state: '', //审核状态
                auditLevel: '', //当前审核人
                subsidyType: '', //身份类别
                keyword: '',
                time: ''
            },
            examineGradeOpt: [
                { label: '街道办事处', value: 2 },
                { label: '区民政局', value: 3 }
            ],
            stateOpt: [
                { label: '暂存', value: 0 },
                { label: '待审核', value: 1 },
                { label: '审核通过', value: 2 },
                { label: '审核不通过', value: 3 }
            ],
            personTypeConfig: [
                { label: '普通老年人', value: 0 },
                { label: '低保老年人', value: 1 },
                { label: '失能老年人', value: 2 },
                { label: '低保失能老年人', value: 3 }
            ]
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        parseTime,
        seeDetial(row) {
            this.$router.push({
                path: '/highCollarSubsidy/applicationReview/viewNewAddApply',
                query: { rowId: row.id }
            })
        },
        toReview(row) {
            this.$router.push({
                path: '/highCollarSubsidy/applicationReview/newAddApplyReview',
                query: { rowId: row.id }
            })
        },
        // 管理人员列表
        async getList() {
            this.loading = false
            let params = JSON.parse(JSON.stringify(this.queryParams))
            params.applyStartTime = ''
            params.applyEndTime = ''
            if (params.time.length) {
                params.applyStartTime = params.time[0]
                params.applyEndTime = params.time[1]
            }
            Reflect.deleteProperty(params, 'time')
            const res = await getApplylist(params)
            this.tableData = res.data.rows
            this.total = res.data.total
        },
        searchList() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.queryParams.pageNum = val
            this.getList()
        },
        handleSizeChange(val) {
            this.loading = true
            this.queryParams.pageSize = val
        },
        handlerResetList() {
            this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                state: '', //审核状态
                auditLevel: '', //当前审核人
                subsidyLevelId: '', //身份类别
                keyword: '',
                time: ''
            }
            this.getList()
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    padding: 20px;
    background: #fff;
}
.manageBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);
    .top {
        .search {
            margin-left: 10px;
        }
        .add {
            float: right;
        }
    }
    .eyes {
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .operation {
        margin-right: 20px;
        color: #3fcf89;
        cursor: pointer;
    }
    .stop {
        color: #f57473;
    }
    .widthStyle {
        width: 90%;
    }

    /*      /deep/ .el-form-item__content {
              margin-left: 55px !important;
              margin-right: 55px;
          }*/
}
.content {
    /*padding: 15px;*/
    padding-top: 20px;
}
.my_btn_duty {
    margin-right: 20px;
}
.el-button--text {
    outline: none;
    border: none;
}
.el-button--text:hover,
.el-button--text:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus {
    color: #c0c4cc !important;
}
.w200px {
    width: 200px;
}
</style>
